<!--
 * @Descripttion : 供应商用户
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-09 10:24:37
 * @LastEditors  : min
 * @LastEditTime : 2020-12-08 17:04:18
-->
<template>
    <div>
        <div style="padding:10px 0;">
            <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
                <a-form-model-item>
                    <a-input-search v-model="formInline.keyWord" placeholder="昵称" allowClear enter-button
                        @search="getList" />
                </a-form-model-item>
                <a-form-model-item v-if="permissions.indexOf('supplier:user:add') > -1">
                    <a-button type="primary" icon="plus" @click="addUser" />
                </a-form-model-item>
            </a-form-model>
        </div>

        <a-radio-group v-model="formInline.status" @change="onChangeStatus" style="margin-bottom:10px;">
            <a-radio-button value="">
                全部
            </a-radio-button>
            <a-radio-button :value="0">
                无效
            </a-radio-button>
            <a-radio-button :value="1">
                正常
            </a-radio-button>
            <a-radio-button :value="2">
                锁定
            </a-radio-button>
        </a-radio-group>

        <a-table :columns="columns" bordered :data-source="data" :rowKey='record => record.id' :pagination="pagination"
            :loading="loading" @change="handleTableChange" size="middle">

            <template slot="supplier" slot-scope="record">
                <div>
                    供应商总部：{{ record.supplierHeadquartersName }}
                </div>
                <div>
                    供应商名称：{{ record.supplierName }}
                </div>
            </template>

            <template slot="status" slot-scope="record">
                <div v-if="record.status == 0">
                    无效
                </div>
                <div v-if="record.status == 1">
                    正常
                </div>
                <div v-if="record.status == 2">
                    锁定
                </div>
            </template>

            <span slot="operation" slot-scope="text, record">
                <template>
                    <a-dropdown>
                        <a class="ant-dropdown-link"> 操作
                            <a-icon type="down" />
                        </a>
                        <a-menu slot="overlay">
                            <a-menu-item v-if="permissions.indexOf('supplier:edit') > -1"
                                @click="editUser(record)">编辑</a-menu-item>
                            <a-menu-item v-if="permissions.indexOf('supplier:user:resetPassword') > -1"
                                @click="onSubmitResetPassword(record)">重置密码</a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </template>
            </span>

            <!-- <template slot="operation" slot-scope="record">
        <a-space>
          <a-button type="primary" size="small" v-if="permissions.indexOf('supplier:user:edit')>-1" icon="edit" @click="editUser(record)" />
        </a-space>
      </template> -->
        </a-table>

        <!-- 新增/编辑用户 -->
        <a-drawer :title="title" :width="300" :visible="visibleUser" @close="onClose" :destroyOnClose="true">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item label="用户名" prop="username">
                    <a-input v-model="form.username" placeholder="请输入用户名" :disabled="form.id !== ''" />
                </a-form-model-item>
                <template v-if="form.id === ''">
                    <a-form-model-item label="密码" prop="password">
                        <a-input v-model="form.password" placeholder="请输入密码" />
                    </a-form-model-item>
                </template>
                <a-form-model-item label="账号所属" prop="type">
                    <a-radio-group v-model="form.type" @change="onChangeRadio">
                        <a-radio value="supplier">
                            供应商
                        </a-radio>
                        <template v-if="userType === 'platform'">
                            <a-radio value="hsupplier">
                                供应商总部
                            </a-radio>
                        </template>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="供应商" prop="defaultSupplier">
                    <a-cascader v-model="form.defaultSupplier" :options="options" placeholder="请选择供应商"
                        @change="onCascaderChange"
                        :fieldNames="{ label: 'name', value: 'number', children: 'supplierTree' }" />
                </a-form-model-item>
                <a-form-model-item label="昵称" prop="nickname">
                    <a-input v-model="form.nickname" placeholder="请输入昵称" />
                </a-form-model-item>
                <a-form-model-item label="手机号" prop="phone">
                    <a-input v-model="form.phone" placeholder="请输入手机号" />
                </a-form-model-item>
                <a-form-model-item label="电话" prop="telephone">
                    <a-input v-model="form.telephone" placeholder="请输入电话" />
                </a-form-model-item>
                <a-form-model-item label="邮箱" prop="email">
                    <a-input v-model="form.email" placeholder="请输入邮箱" />
                </a-form-model-item>
                <a-form-model-item label="生日" prop="birthday">
                    <a-date-picker v-model="form.birthday" :format="dateFormat" placeholder="请选择生日" :showToday="false"
                        @change="onChange">
                    </a-date-picker>
                </a-form-model-item>
                <a-form-model-item label="性别" prop="sex">
                    <a-radio-group name="sex" v-model="form.sex">
                        <a-radio :value="1">
                            男
                        </a-radio>
                        <a-radio :value="0">
                            女
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <template v-if="form.id !== ''">
                    <a-form-model-item label="状态" prop="status">
                        <a-radio-group name="status" v-model="form.status">
                            <a-radio :value="0">
                                无效
                            </a-radio>
                            <a-radio :value="1">
                                正常
                            </a-radio>
                            <a-radio :value="2">
                                锁定
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </template>
                <a-form-model-item label="角色" prop="roleIds">
                    <div
                        style="max-height:200px;background-color:#f1f1f1;overflow: auto;padding: 5px;margin-bottom:10px;">
                        <a-checkbox-group v-model="form.roleIds">
                            <template v-for="(item, index) in roleList">
                                <a-checkbox :value="item.id" :key="index">
                                    {{ item.name }}
                                </a-checkbox>
                            </template>
                        </a-checkbox-group>
                    </div>
                </a-form-model-item>
                <div class="ml_drawer_footer">
                    <a-button @click="onClose">
                        取消
                    </a-button>
                    <a-button style="margin-left: 10px" type="primary" @click="onSubmit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
    </div>
</template>
<script>
import {
    addSupplierUser,
    updateSupplierUser,
    getSupplierUserByID,
    getSupplierUserList,
    getSupperPulldownList
} from "@/api/supplier";

import {
    resetPassword
} from "@/api/users";

import { getRoleByUserID } from "@/api/system";
import { validatePhone, validatePhoneTwo } from "@/utils/verification";

import { getRoleAll } from "@/api/system";
import { mapState } from "vuex";

import moment from "moment";

const columns = [
    { title: "登录名", dataIndex: "username", align: "center" },
    { title: "昵称", dataIndex: "nickname", align: "center" },
    { title: "手机号", dataIndex: "phone", align: "center" },
    { title: "邮箱", dataIndex: "email", align: "center" },
    { title: "状态", scopedSlots: { customRender: "status" }, align: "center" },
    { title: "角色", dataIndex: "roleNameStr", align: "center" },
    {
        title: "供应商",
        scopedSlots: { customRender: "supplier" }
    },
    {
        title: "操作",
        scopedSlots: { customRender: "operation" },
        align: "center"
    }
];

export default {
    data() {
        return {
            columns,
            data: [],
            loading: false,
            pagination: {
                pageSize: 10,
                pageSizeOptions: ["10", "20", "30", "40"],
                showSizeChanger: true,
                current: 1,
                total: 0
            },
            visibleUser: false, //  是否显示操作
            formInline: {
                keyWord: "",
                status: ""
            },
            title: "",
            form: {
                id: "",
                username: "", //  用户名
                password: "",
                nickname: "", //  昵称
                phone: "", //  手机号
                telephone: "", //  电话
                email: "", //  邮箱
                birthday: null, //  生日
                sex: 1, //  性别
                status: 1,
                type: "supplier", //Type=supplier:供应商,Type=hsupplier:供应商总部
                supplierNumber: "",
                defaultSupplier: [], //  默认供应商
                roleIds: []
            },
            rules: {
                username: [{ required: true, message: "请输入用户名" }],
                password: [{ required: true, message: "请输入密码" }],
                phone: [{ validator: validatePhone, trigger: "change" }],
                defaultSupplier: [{ required: true, message: "请选择供应商" }],
                telephone: [{ validator: validatePhoneTwo, trigger: "change" }]
            },
            roleList: [],
            dateFormat: "YYYY-MM-DD",
            optionsData: [], //  供应商数据
            options: [],  //  供应商
        };
    },
    computed: {
        ...mapState({
            permissions: state => state.userStore.permissions,
            userType: state => state.userStore.type
        })
    },
    mounted() {
        this.getList();
        this.getBaseInfo();
    },
    methods: {
        onChangeRadio(e) {
            // 更改账号所属
            const that = this;
            that.options = [];
            that.form.defaultSupplier = [];
            if (e.target.value == "hsupplier") {
                // 供应商总部
                that.optionsData.forEach(element => {
                    that.options.push({
                        name: element.name,
                        number: element.number
                    })
                });
            } else {
                // 供应商
                let _str = JSON.stringify(that.optionsData);
                that.options = JSON.parse(_str);
            }
        },
        getBaseInfo() {
            const that = this;
            getRoleAll({
                _: new Date().getTime()
            }).then(res => {
                // that.roleList = res.data;
                res.data.map(e => {
                    if (e.type == 2) {
                        that.roleList.push(e)
                    }
                })
            });

            getSupperPulldownList().then(res => {
                that.optionsData = res.data;
                that.options = res.data;
            });
        },
        onChangeStatus() {
            this.pagination.current = 1;
            this.getList();
        },
        getList() {
            //   获取用户列表
            const that = this;
            that.loading = true;
            getSupplierUserList({
                keyWord: that.formInline.keyWord || null,
                status: that.formInline.status,
                pageNum: that.pagination.current,
                pageSize: that.pagination.pageSize,
                _: new Date().getTime()
            })
                .then(res => {
                    that.loading = false;
                    that.data = res.data.list;
                    that.pagination.total = res.data.total;
                })
                .catch(err => {
                    console.log(err);
                    that.loading = false;
                });
        },
        addUser() {
            const that = this;
            that.title = "新增用户";
            that.form.id = "";
            that.visibleUser = true;

            // that.$nextTick(() => {
            //   that.$refs.ruleForm.resetFields();
            //   that.form.birthday = null;
            // });
        },
        editUser(e) {
            const that = this;
            that.title = "编辑用户";
            that.visibleUser = true;
            // that.$nextTick(() => {
            //   that.$refs.ruleForm.resetFields();
            // });

            getSupplierUserByID(e.id, {
                _: new Date().getTime()
            }).then(res => {
                that.form.id = res.data.id;
                that.form.username = res.data.username; //  用户名
                that.form.nickname = res.data.nickname; //  昵称
                that.form.phone = res.data.phone; //  手机号
                that.form.telephone = res.data.telephone; //  电话
                that.form.email = res.data.email; //  邮箱
                if (res.data.birthday) {
                    that.form.birthday = moment(res.data.birthday, "YYYY-MM-DD"); //  生日
                }
                that.form.sex = res.data.sex; //  性别
                that.form.status = res.data.status; //  状态

                that.form.supplierNumber = res.data.supplierNumber || "";
                that.form.type = res.data.type;

                that.form.defaultSupplier = [];

                that.options = [];
                if (res.data.supplierNumber) {
                    if (that.form.type == "hsupplier") {
                        // 供应商总部
                        that.optionsData.forEach(element => {
                            that.options.push({
                                name: element.name,
                                number: element.number
                            });

                            if (element.number == res.data.supplierNumber) {
                                that.form.defaultSupplier.push(element.number);
                            }
                        });
                    } else {
                        // 供应商
                        let _str = JSON.stringify(that.optionsData);
                        that.options = JSON.parse(_str);
                        that.options.forEach(element => {
                            if (element.supplierTree) {
                                element.supplierTree.forEach(item => {
                                    if (item.number == res.data.supplierNumber) {
                                        that.form.defaultSupplier.push(element.number);
                                        that.form.defaultSupplier.push(item.number);
                                    }
                                });
                            }
                        });
                    }
                }
            });
            that.form.roleIds = [];
            getRoleByUserID({
                userId: e.id,
                _: new Date().getTime()
            }).then(res => {
                res.data.forEach(element => {
                    that.form.roleIds.push(element.id);
                });
            });
        },
        onSubmit() {
            // 提交角色
            const that = this;
            that.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (that.form.type == "hsupplier") {
                        that.form.supplierNumber = that.form.defaultSupplier[0];
                    } else {
                        that.form.supplierNumber = that.form.defaultSupplier[1];
                    }
                    that.form["json"] = true;
                    if (that.form.id) {
                        updateSupplierUser(that.form).then(() => {
                            that.$message.success({
                                content: "修改成功！",
                                duration: 1,
                                onClose: () => {
                                    that.clearFormFn();
                                    that.visibleUser = false;
                                    that.getList();
                                }
                            });
                        });
                    } else {
                        addSupplierUser(that.form).then(() => {
                            that.$message.success({
                                content: "添加成功！",
                                duration: 1,
                                onClose: () => {
                                    that.clearFormFn();
                                    that.visibleUser = false;
                                    that.getList();
                                }
                            });
                        });
                    }
                }
            });
        },
        onClose() {
            const that = this;
            that.$refs.ruleForm.resetFields();
            that.form.birthday = null;
            that.visibleUser = false;
        },
        handleTableChange(pagination, filters, sorter) {
            console.log(pagination, filters, sorter);
            this.pagination = { ...pagination };
            this.getList();
        },
        onChange(date, dateString) {
            console.log(date);
            if (dateString) {
                this.form.birthday = moment(dateString, "YYYY-MM-DD");
            }
        },
        onCascaderChange(value) {
            this.form.defaultSupplier = value;
        },
        onSubmitResetPassword(e) {
            const that = this;
            that.$confirm({
                title: '提示',
                content: '您确定重置密码吗？',
                onOk() {
                    that.resetPasswordFn(e.id);
                },
                onCancel() { },
            });
        },
        resetPasswordFn(id) {
            const that = this;
            resetPassword(id).then(() => {
                that.$success({
                    title: '提示',
                    content: '密码已重置，新密码：123456',
                    onOk() {
                        that.getList();
                    },
                });
            });
        },
        clearFormFn() {
            const that = this;
            that.form.id = '';
            that.form.username = '';
            that.form.password = '';
            that.form.nickname = '';
            that.form.phone = '';
            that.form.telephone = '';
            that.form.email = '';
            that.form.birthday = null;
            that.form.sex = 1;
            that.form.status = 1;
            that.form.type = 'supplier';
            that.form.supplierNumber = '';
            that.form.defaultSupplier = [];
            that.form.roleIds = [];
        },
    }
};
</script>